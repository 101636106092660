const mixin = {
  data: () => ({
    calendarInputProps: {
      'prepend-icon': 'event',
      height: 30,
      clearable: true,
    },
  }),
  methods: {
    applyFilters() {
      const {
        currentItems,
        reportId,
        reportKey,
        subId,
      } = this

      const report = { items: currentItems, reportId, reportKey, subId }

      this.$store.dispatch('tools/applyFilters', report)
    },
    onResetFilters() {
      this.$store.dispatch('tools/resetFilters')
    },
    onToggleFilters(displayFilter) {
      this.$store.dispatch('tools/displayFilter', displayFilter)
    },
  },
}

export default mixin
